<template>
  <customer-segmentation />
</template>

<script>
import CustomerSegmentation from '@/components/pages/customer/CustomerSegmentation'

export default {
  components: {
    CustomerSegmentation
  }
}
</script>
