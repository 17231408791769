<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Customer"
        title="Customer Segmentation"
        subTitle="How Do I Upload My Own List Of Customers Into A Segment?"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="row q-col-gutter-md">
      <div class="col-xs-12 col-sm-6 col-md-4">
        <FilterCard title="Upload Customer Segments">
          <q-form ref="filterForm" @submit="onUpload" @reset="onReset">
            <div class="q-mb-lg col q-gutter-md">
              <div>
                <FormInput
                  label="* Segment Name"
                  icon="label"
                  v-model="segmentName"
                  :rules="[VALIDATION.required]"
                  tabindex="1"
                />
              </div>
              <div>
                <FormInput
                  type="textarea"
                  label="* Segment Description"
                  icon="description"
                  v-model="segmentDesc"
                  :rules="[VALIDATION.required]"
                  tabindex="2"
                />
              </div>
            </div>
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <FormFile
                  label="* Select a File"
                  v-model="file"
                  accept="application/csv, text/csv, application/vnd.ms-excel"
                  :rules="[VALIDATION.requiredFile]"
                  :filter="checkFileType"
                  @onFileError="onFileError"
                  tabindex="3"
                />
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 text-right">
                <Button
                  variant="primary"
                  label="Upload"
                  icon="o_file_upload"
                  :loading="loadingUpload"
                  :disabled="loadingUpload"
                  type="submit"
                  tabindex="4"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-8">
        <Table
          :rows="listTableData"
          :columns="listTableHeader"
          :loading="loadingLists"
        />
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">Customer Segment Uploader</h5>
    <p>
      Customer Segment Uploader is an easy to use tool to upload a specific
      customer list that the user wants to group into a segment.
    </p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Fields</h6>
    <p>
      <strong>Segment Name:</strong> The name of the segment you want to be use
      on other pages.
    </p>
    <p>
      <strong>Segment Description:</strong> Tells the user on what these segment
      is for.
    </p>
    <p>
      <strong>File:</strong> Lists of data to be uploaded.
      <i>(required format below)</i>
    </p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">File Template</h6>
    <p>To use the segment uploader use this template in csv type of file.</p>
    <br />
    <p class="bg-black q-pa-md text-white text-caption rounded">
      firstname,lastname,mobile,email
      <br />
      Juan,Dela Cruz,639199999999,juan@mail.com
      <br />
      John,Santos,639198888888,john@mail.com
    </p>
  </Modal>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard } from '@/components/cards'
import { FormInput, FormFile } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  fetchData,
  getIconUrl
} from '@/tools'

export default {
  name: 'CustomerSegmentationUpload',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    FormInput,
    FormFile,
    Button,
    Table,
    Modal
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const {
      data: dataUpload,
      error: errorUpload,
      loading: loadingUpload,
      upload: postUpload
    } = fetchData()

    const {
      data: dataImport,
      error: errorImport,
      loading: loadingImport,
      post: postImport
    } = fetchData()

    const showModal = ref(false)
    const filterForm = ref(null)
    const segmentName = ref(null)
    const segmentDesc = ref(null)
    const file = ref([])
    const uploadedFile = ref(null)
    const listTableData = ref([])

    const listTableHeader = ref([
      {
        name: 'id',
        label: '#',
        field: 'id',
        align: 'center',
        sortable: true
      },
      {
        name: 'name',
        label: 'Name',
        field: 'name',
        align: 'left',
        sortable: true
      },
      {
        name: 'desc',
        label: 'Description',
        field: 'desc',
        align: 'left',
        sortable: true
      },
      {
        name: 'segmentType',
        label: 'Segment Type',
        field: 'segmentType',
        align: 'center',
        sortable: true
      },
      {
        name: 'createdAt',
        label: 'Created At',
        field: 'createdAt',
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => listTableData.value && listTableData.value.length > 0
    )
    const noData = computed(
      () => listTableData.value && listTableData.value.length === 0
    )

    const checkFileType = files => {
      const allowedFileTypes = [
        'text/csv',
        'application/csv',
        'application/vnd.ms-excel'
      ]

      return files.filter(file => {
        const extension = file?.name.split('.').pop()
        return extension === 'csv' && allowedFileTypes.includes(file.type)
      })
    }

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const getTableLists = () => {
      getLists('/v1/customer-segmentation/manual')
    }

    const onUpload = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          const payload = file.value
          postUpload('v1/customer-segmentation/manual/upload', payload)
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onImport = () => {
      const payload = {
        fileName: uploadedFile.value,
        name: segmentName.value,
        description: segmentDesc.value
      }
      postImport('v1/customer-segmentation/manual/import', payload)
    }

    const onReset = () => {
      filterForm.value.resetValidation()
      segmentName.value = null
      segmentDesc.value = null
      file.value = []
    }

    const onFileError = rejectedEntries => {
      if (rejectedEntries) {
        showToast('Invalid file type. Accepts csv file only.', 'info')
      }
    }

    const exportTable = () => {
      EXPORT.exportToExcel(
        listTableHeader.value,
        listTableData.value,
        'customer_segmentations'
      )
    }

    onBeforeMount(() => {
      getTableLists()

      watch([dataLists, errorLists, loadingLists], () => {
        if (!loadingLists.value) {
          if (errorLists?.value) {
            showToast(
              errorLists?.value?.errors
                ? errorLists?.value?.errors[0]?.msg
                : errorLists?.value?.message ??
                    'There was a problem fetching the lists.',
              'danger'
            )
          } else if (dataLists?.value) {
            listTableData.value =
              dataLists?.value?.map(item => ({
                id: item?.id,
                name: item?.name,
                desc: item?.desc,
                segmentType: item?.segment_type,
                createdAt: DATE.toFriendlyDateTime(item?.created_at)
              })) ?? []
          }
        }
      })

      watch([dataUpload, errorUpload, loadingUpload], () => {
        if (loadingUpload.value) {
          uploadedFile.value = null
        } else {
          if (errorUpload?.value) {
            showToast('There was a problem uploading the file.', 'danger')
          } else if (dataUpload?.value) {
            if (dataUpload?.value?.status) {
              uploadedFile.value = dataUpload?.value?.path
              onImport()
            }
          } else {
            uploadedFile.value = []
          }
        }
      })

      watch([dataImport, errorImport, loadingImport], () => {
        if (!loadingImport.value) {
          if (errorImport?.value) {
            showToast('There was a problem uploading the file.', 'danger')
          } else if (dataImport?.value) {
            if (dataImport?.value?.customer_segment_id?.length > 0) {
              showToast('File successfully uploaded!', 'success')
              getTableLists()
              onReset()
            }
          }
        }
      })
    })

    return {
      showModal,
      filterForm,
      segmentName,
      segmentDesc,
      file,
      uploadedFile,
      listTableHeader,
      listTableData,
      loadingLists,
      loadingUpload,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      checkFileType,
      onToggleModal,
      onUpload,
      onReset,
      onFileError,
      exportTable,
      json_fields: {
        'Complete name': 'name',
        City: 'city',
        Telephone: 'phone.mobile',
        'Telephone 2': {
          field: 'phone.landline',
          callback: value => {
            return `Landline Phone - ${value}`
          }
        }
      },
      json_data: [
        {
          name: 'Tony Peña',
          city: 'New York',
          country: 'United States',
          birthdate: '1978-03-15',
          phone: {
            mobile: '1-541-754-3010',
            landline: '(541) 754-3010'
          }
        },
        {
          name: 'Thessaloniki',
          city: 'Athens',
          country: 'Greece',
          birthdate: '1987-11-23',
          phone: {
            mobile: '+1 855 275 5071',
            landline: '(2741) 2621-244'
          }
        }
      ],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ]
    }
  }
}
</script>
